jQuery(document).ready(function () {
  jQuery(".slick-slider").slick({autoplay: true, autoplaySpeed: 3000, dots: true, arrows: true, infinite: true, fade: true});
  //mobileMenuInit();
  // if (document.documentElement.clientWidth < 1024) {
  //   Acordeon(jQuery("body"), ".moduletable_btn-top-menu-mobile", ".moduletable_categ_menu");
  //   Acordeon(jQuery("body"), ".moduletable_btn-open-phone-mobile", ".moduletable_phones");
   //  Acordeon(jQuery("body"), ".moduletable_btn-open-serch-mobile", ".moduletable_search");
  //   Acordeon(jQuery("body"), ".moduletable_btn-open-menu", ".moduletable_menu");
  // }
});
function mobileMenuInit() {
  var body = jQuery("body"),
    mobile_menu = jQuery(".mobile_menu"),
    opener = jQuery(".opener");
  opener.click(function () {
    jQuery(this).toggleClass("active");
    body.toggleClass("body_move");
    mobile_menu.toggleClass("active");
  });
  jQuery(window).bind("resize", function () {
    if (jQuery(window).width() > 767 && body.hasClass("body_move")) {
      opener.removeClass("active");
      body.removeClass("body_move");
      mobile_menu.removeClass("active");
    }
  });
}

jQuery(document).ready(function (a) {
  function b() {
    a(".nav .parent").addClass("dropdown");
    a(".nav .parent > a").addClass("dropdown-toggle");
    a(".nav .parent > ul").addClass("dropdown-menu");
  }
  b();
});
function Acordeon(b, d, e) {
  var g = jQuery(document),
    i = b,
    h = i.find(d),
    c = i.find(e);
  h.on("click", function (m) {
    m.preventDefault();
    var l = jQuery(this),
      j = l.closest(i),
      k = j.find(c);
    if (j.hasClass("active-p")) {
      j.removeClass("active-p");
      k.slideUp(500);
    } else {
      c.slideUp(500);
      i.removeClass("active-p");
      j.addClass("active-p");
      k.slideDown(500);
      return false;
    }
  });
  function f(j) {
    if (!jQuery(j.target).closest(c).length) {
      c.slideUp(500);
      i.removeClass("active-p");
    }
  }
  g.on("click", f);
  function a(j) {
    g.keyup(function (k) {
      if (k.keyCode === 27) {
        c.slideUp(500);
        i.removeClass("active-p");
      }
    });
  }
  g.on("click", a);
}

